<template>
  <section
    class="title-2-columns"
    :id="data.sectionId"
    :class="data.backgroundColor === 'white' ? 'bg-white' : ''"
  >
    <div class="container grid-main">
      <div
        v-if="data.title && !data.isLink"
        :class="data.text ? 'lg:col-span-9 col-span-12' : 'col-span-12'"
      >
        <h1 class="max-w-4xl">
          {{ data.title }}
        </h1>
      </div>

      <NuxtLink
        v-else-if="data.title && data.isLink && data.page && data.page._ref"
        :class="data.text ? 'lg:col-span-9 col-span-12' : 'col-span-12'"
        class="uppercase no-underline"
        :to="$fullpath(data.page._ref)"
      >
        <ArrowLink
          :text="data.title"
          :tag="'h1'"
        />
      </NuxtLink>
      <div class="text mt-3 lg:mt-0 col-span-12 lg:col-span-3">
        <BoldPortableText :blocks="data.text" />

        <Button
          v-if="data.button"
          class="mt-3 w-fit"
          :to="
            data.button.isExternal ? data.button.externalLink : $fullpath(data.button.link?._ref)
          "
          :isExternal="data.button.isExternal || false"
          :target="data.button.isExternal ? '_blank' : ''"
          variant="primary"
          variantType="normal"
          >{{ data.button.label }}
        </Button>
      </div>
    </div>
    <Separator v-if="data.bottomStroke?.isActive" />
  </section>
</template>

<script setup lang="ts">
interface title2columnsProps {
  data: any;
}

defineProps<title2columnsProps>();

const { $fullpath } = useSiteSettings();
</script>

<style lang="postcss">
.title-2-columns {
  .text {
    p {
      /* @apply whitespace-pre break-all; */
    }
  }
}
</style>
